<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :id="$route.params.id"
    :showDeleteButton="true"
  />
</template>
<script>
import { db } from "@/firebase/firebaseConfig";

import FormPage from "@/components/penal-retail-helpers/FormPage";
let collectionName = "lawyers";

import {
  createRelatedCustomersColumns,
  createRelatedEstablishmentsColumns,
  createRelatedCasesColumns,
} from "@/helpers/utils";

import lawyersFormConfig from "@/helpers/lawyersFormConfig";

let watch = {};

export default {
  components: {
    FormPage,
  },
  watch,
  created() {
    this.$bind(
      "customers",
      db.collection("customers").where("lawyer.id", "==", this.$route.params.id)
    );
    this.$bind(
      "establishments",
      db
        .collection("establishments")
        .where("lawyer.id", "==", this.$route.params.id)
    );
    this.$bind(
      "cases",
      db
        .collection("cases")
        .where("lawyer.id", "==", this.$route.params.id)
        .where("deleted", "==", false)
    );
  },
  data() {
    let attributes = [].concat(lawyersFormConfig);

    let data = {
      allCases: [],
      customers: [],
      collectionName,
      tabs: [
        { name: `${collectionName}.tabs.general`, attributes: attributes },
        {
          name: `${collectionName}.tabs.customers`,
          attributes: this.defineCustomersTab(),
        },
        {
          name: `${collectionName}.tabs.establishments`,
          attributes: this.defineEstablishmentsTab(),
        },
        {
          name: `${collectionName}.tabs.cases`,
          attributes: this.defineCasesTab(),
        },
      ],
    };

    return data;
  },
  methods: {
    defineCustomersTab() {
      let columns = createRelatedCustomersColumns(this);

      let config = [];
      config.push({
        size: "1/1",
        type: "list",
        extra: {
          data: () => {
            return this.customers;
          },
          filters: [],
          gridConfig: {
            components: {},
            gridOptions: {},
            columnDefs: columns,
            defaultColDef: {
              sortable: true,
              resizable: true,
            },
            onRowClicked: (params) => {
              this.$router
                .push("/settings/customers/" + params.data.id)
                .catch(() => {});
            },
          },
        },
      });

      return config;
    },
    defineEstablishmentsTab() {
      let columns = createRelatedEstablishmentsColumns(this);

      let config = [];
      config.push({
        size: "1/1",
        type: "list",
        extra: {
          data: () => {
            return this.establishments;
          },
          filters: [],
          gridConfig: {
            components: {},
            gridOptions: {},
            columnDefs: columns,
            defaultColDef: {
              sortable: true,
              resizable: true,
            },
            onRowClicked: (params) => {
              this.$router
                .push("/settings/establishments/" + params.data.id)
                .catch(() => {});
            },
          },
        },
      });

      return config;
    },
    defineCasesTab() {
      let columns = createRelatedCasesColumns(this);

      let config = [];
      config.push({
        size: "1/1",
        type: "list",
        extra: {
          data: () => {
            return this.cases;
          },
          filters: [],
          gridConfig: {
            components: {},
            gridOptions: {},
            columnDefs: columns,
            defaultColDef: {
              sortable: true,
              resizable: true,
            },
            onRowClicked: (params) => {
              this.$router
                .push("/main/cases/" + params.data.id)
                .catch(() => {});
            },
          },
        },
      });

      return config;
    },
    async prevalidate(data, collection, errors) {
      let snapshot = await collection.where("dni", "==", data.dni).get();

      if (snapshot.docs && snapshot.docs.length > 0) {
        if (
          snapshot.docs.length > 1 ||
          snapshot.docs[0].id != this.$route.params.id
        ) {
          errors.add({
            field: "dni",
            msg: this.$t(`Validations.${collectionName}.dni.unique`),
          });
          return true;
        }
      }

      return false;
    },
  },
};
</script>
